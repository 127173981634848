import React from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { MAIN_COLOR, SITE } from "../config";

const StyledLink = styled(Link)`
  ${tw`bg-[#9561a9] text-white px-4 py-1 rounded hover:opacity-70`}
`;
const Container = styled.div`
  ${tw`p-5`}
`;
const TextSection = styled.div`
  ${tw`p-2`}
`;
const Header = styled.h1`
  ${tw`text-5xl font-bold text-center`}
`;
const ImageContainer = styled.div`
  ${tw`flex justify-center`}
`;

const SubHeader = styled.h4`
  ${tw`text-xl font-bold text-[#9561a9] mt-2 text-center`}
`;
const Paragraph = styled.p`
  ${tw`mt-2 text-center text-gray-700`}
`;
const LinkContainer = styled.div`
  ${tw`flex justify-center my-6`}
`;

const ComingSoonPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, hero, tabs },
    },
  },
  location,
}) => {
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        <TextSection>
          <Header color={MAIN_COLOR} site={SITE}>
            {title}
          </Header>
          <SubHeader>
            We're almost ready! Check back later for something new and exciting.
          </SubHeader>
          <Paragraph>
            Please email info@calgarydiamondbacks.com for inquiries.
          </Paragraph>
        </TextSection>
        <ImageContainer>
          <StaticImage
            src="../../static/img/coming-soon.jpg"
            alt="coming soon"
            placeholder="tracedSVG"
            layout="fixed"
            height={350}
          />
        </ImageContainer>
      </Container>
      <LinkContainer>
        <StyledLink to="/">Return Home</StyledLink>
      </LinkContainer>
    </Layout>
  );
};

export default ComingSoonPage;

export const pageQuery = graphql`
  query ComingSoonPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(height: 250)
            }
          }
          pageImagePosition
        }
      }
    }
  }
`;
